module services {
    export module costing {
        export class costModelElementService implements interfaces.costing.ICostModelElementService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(ownerEntityId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CostModelElement/GetDropdownList", {
                    ownerEntityId: ownerEntityId,
                });
            }
        }
    }
    angular.module("app").service("costModelElementService", services.costing.costModelElementService);
}